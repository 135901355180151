










































import { Component, Prop, Mixins } from 'vue-property-decorator'
import ChartDonutSubject from '@/components/molecules/ChartDonutSubject.vue'
import ChartFillPattern from '@/components/atoms/ChartFillPattern.vue'

@Component({
  components: {
    ChartDonutSubject,
  },
})
export default class StudentHistoryTotal extends Mixins(ChartFillPattern) {
  @Prop()
  total!: {}

  @Prop({ default: false })
  isTeacher?: boolean

  get styleSet(): any {
    const _: any = this
    return function (key: string) {
      return {
        '--background-image': 'url(' + _.getPattern(key).src + ')',
        '--background-size': _.isTeacher ? '50%' : 'cover',
      }
    }
  }

  get anchorSet(): any {
    return function (key: string) {
      return '#' + key
    }
  }
}
